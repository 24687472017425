import React from "react";
import "./Sandwiches.css";
import PagesNav from "../components/PagesNav";
import Footer from "../components/Footer";
import wtcoldsandwich from "../menus/wtcoldsandwich.json";
import wthotsandwich from "../menus/wthotsandwich.json";
import hoagies from "../menus/hoagies.json";

function Sandwiches() {
  return (
    <>
      <PagesNav />
      <div className="hero">
        <div
          style={{ textAlign: "center", height: 300 }}
          className="sandwichHeader"
        >
          <h1
            className="wow fadeInUp"
            style={{ color: "white", textShadow: "1px 1px 17px black" }}
          >
            Hoagies & Sandwiches
          </h1>
          <div class="horizontal-line"></div>
          <h5
            className="wow fadeInDown"
            style={{ color: "white", textShadow: "1px 1px 17px black" }}
          >
            Italian style hoagies and sandwiches
          </h5>
        </div>
      </div>
      <h2 className="text-center mt-0 titleText wow fadeIn delay-1s">Menu</h2>
      <div class="horizontal-line wow fadeIn delay-1s"></div>
      <div className="text-center wow fadeIn delay-1s">
        All sandwiches are made fresh in house upon ordering.
        <div className="calltoorder">
          Call us to place an order{" "}
          <a href="tel:+1-856-939-1070">(856) 939-1070</a>
        </div>
        <br />
      </div>
      {/* HOT SANWICHES */}
      <div className="deliTable wow fadeIn delay-1s">
        <table className="table-responsive">
          <table className="table table-hover">
            <tr>
              <th
                align="left"
                style={{ textTransform: "uppercase", fontSize: "20px" }}
              >
                Hot sandwiches
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  6" $6.50 • 12" $12.00
                </div>
              </th>
            </tr>
            {/* new row */}
            {wthotsandwich.map((item) => (
              <tr>
                <td align="left">
                  <div
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    {item.item}
                  </div>
                  <div className="small muted">{item.description}</div>
                </td>
              </tr>
            ))}
          </table>
        </table>
      </div>
      <br />
      {/* COLD SANDWICHES */}
      <div className="deliTable wow fadeIn delay-1s">
        <table className="table-responsive">
          <table className="table table-hover">
            <tr>
              <th
                align="left"
                style={{ textTransform: "uppercase", fontSize: "20px" }}
              >
                cold hoagies
                <div
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  6" $6.50 • 12" $11.00
                </div>
              </th>
            </tr>
            {/* new row */}
            {wtcoldsandwich.map((item) => (
              <tr>
                <td align="left">
                  <div
                    style={{ textTransform: "uppercase", fontWeight: "bold" }}
                  >
                    {item.item}
                  </div>
                  <div className="small muted">{item.description}</div>
                </td>
              </tr>
            ))}
          </table>
        </table>
      </div>
      <div className="hoagieTable wow fadeIn delay-1s">
        <table className="table-responsive">
          <table className="table table-hover">
            <tr>
              <th align="left" style={{ textTransform: "uppercase" }}>
                Hoagies & Party Trays
              </th>
              <th align="left" style={{ textTransform: "uppercase" }}>
                Price
              </th>
            </tr>
            {hoagies.map((item) => (
              <tr>
                <td align="left">{item.hoagie}</td>
                <td align="left">{item.hPrice}</td>
              </tr>
            ))}
          </table>
        </table>
      </div>
      <Footer />
    </>
  );
}

export default Sandwiches;
