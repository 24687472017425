import React from "react";

// Footer

function Footer() {
  return (
    <footer class="bg-white py-5 sticky-bottom">
      <div class="container">
        <div class="small text-center text-muted">
          Copyright © 2024 • G&M Market
        </div>
      </div>
    </footer>
  );
}

export default Footer;
