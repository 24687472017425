import React from "react";
import { Link } from "react-router-dom";

// Menu

function Menu() {
  return (
    <section className="page-section-menu" id="menu">
      <h2 className="text-center mt-0 aboutHeadline wow bounceInDown">Menu</h2>
      <div class="horizontal-line"></div>
      <div className="container wow bounceInUp">
        <div className="row text-center">
          <div className="col-md-2"></div>
          <div className="col-md-2 deli">
            <Link to="/deli">
              <button type="button" class="btn btn-lg">
                Our Deli
              </button>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/italian-specialties">
              <button type="button" class="btn btn-lg">
                Italian Specialties
              </button>
            </Link>
          </div>
          <div className="col-md-2">
            <Link to="/butcher">
              <button type="button" class="btn btn-lg">
                Our Meats
              </button>
            </Link>
          </div>
          <div className="col-md-4"></div>
        </div>
        <br />
        <div className="row text-center">
          <div className="col-md-3"></div>
          <div className="col-md-2 deli">
            <Link to="/catering">
              <button type="button" class="btn btn-lg">
                Catering
              </button>
            </Link>
          </div>
          <div className="col-md-3">
            <Link to="/hoagies-sandwiches">
              <button type="button" class="btn btn-lg">
                Hoagies & Sandwiches
              </button>
            </Link>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4"></div>
          <br />
          <br />
          <div className="text-center mt-4 catering wow fadeIn delay-1s">
            Call to place an order •{" "}
            <a href="tel:+1-856-939-1070">(856) 939-1070</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Menu;
